export default {
  data(){
    return{
      patientTypes: [],
    }
  },
  mounted(){
    this.getPatientTypes()
  },
  methods: {
    async getPatientTypes() {
      try {
        let response = await axios.get(
          `${this.$store.getters.getBaseUrl}/api/admin/patient-types`
        );
        let {
          data: { data },
        } = response;
        this.patientTypes = data;
      } catch (error) {

      }
    },
  }
}
